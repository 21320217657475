<template>
  <div
    class="ce-container_v2 ce-app"
    :class="{'ce-android-container': isAndroid}"
  >
    <ce-header />
    <ce-header-mobile />
    <div class="ce-view-container">
      <router-view />
    </div>
    <template v-if="!isAndroid && !isServiceMan">
      <div
        v-if="cookieFlag"
        class="Add_Cookies Global_Text Global_Text_FontSize_L Global_Text_Color_Standart ce-cookies"
      >
        <div class="Add_Cookies_Block">
          <p class="G_Text_Small_R Add_Cookies_Text">
            {{ $t($k.menu_cookieAlert) }}
          </p>
          <div
            class="Add_Cookies_Close"
            @click="changeCookieFlag"
          >
            <p class="Add_Cookies_Close_text">
              Ok
            </p>
          </div>
        </div>
      </div>
      <ce-footer />
    </template>
    <ce-bottom-sticky v-if="!isProductionHostname && !isServiceMan" />
    <ce-mobile-menu v-if="showMobileMenu" />
    <notifications
      position="top right"
      classes="Notification"
    />
    <!--    <loader-component />-->
    <!--    <modal-window />-->
    <!--    <form-join />-->
    <ce-cash-exchange-popup
      v-if="showCashExchangePopup && Authorized"
      @close="setShowCashierForm(false)"
    />
    <ce-cash-service-form
      v-if="showCashierForm"
      @close="setVisibleCashierForm(false)"
    />
    <ce-support-popup
      v-if="showSupportPopup"
      @close="setVisibleSupportPopup(false)"
    />
  </div>
</template>

<script>
/*eslint-disable */
import Store from '@/store'
import axios from 'axios'
import router from '@/router'
import { app as Vue } from '@/entry-files/main'
import { hideAllPoppers } from 'floating-vue'
import { WINDOW_MODE_NAMES, WINDOW_MODE_SIZES } from '@/constants/common'
import { mapGetters, mapMutations, mapState } from 'vuex'
import CeHeader from "@/components/common/CeHeader.vue";
import CeHeaderMobile from "@/components/common/CeHeaderMobile.vue";
import CeFooter from "@/components/common/CeFooter.vue";
import CeBottomSticky from "@/components/common/CeBottomSticky.vue";
import CeMobileMenu from "@/components/common/CeMobileMenu.vue";
import { SERVICE_MAN_ROUTE_NAMES } from "@/constants/admin";
import { App as CapacitorApp } from '@capacitor/app'
import { getQueryObjectFromString } from '@/utils/common'
import { getCashierInfoFromQuery } from '@/utils/cash'
import CeCashExchangePopup from '@/components/CeCashExchangePopup.vue'
import fbToken from './Plugins/FbToken'
import CeCashServiceForm from '@/components/CashService/CeCashServiceForm.vue'
import CeSupportPopup from '@/components/CeSupportPopup.vue'
import { usePrimeVue } from 'primevue/config'
import primevueLocales from '@/constants/primevue-locales'

window.onfocus = function () {
  Store.commit('ChangeTitleAlert', '')
  Vue.$StopChange()
}

window.onscroll = hideAllPoppers

axios.interceptors.response.use(function (response) {
  return response
}, async function (error) {
  if (error?.response?.status === 401) {
    await Store.dispatch('signOut')
    if (window.location.pathname !== '/lk/login') router.push('/lk/login')
  }
  return Promise.reject(error)
})

export default {
  metaInfo () {
    return {
      title: 'Main',
      titleTemplate: `${this.titleAlert} %s | Cryptoenter`
    }
  },
  setup () {
    const primeVue = usePrimeVue()
    const changePrimeVueLocale = (to) => {
      primeVue.config.locale = primevueLocales[to]
    }
    return {
      changePrimeVueLocale
    }
  },
  components: {
    CeSupportPopup,
    CeCashServiceForm,
    CeCashExchangePopup,
    CeMobileMenu,
    CeBottomSticky,
    CeFooter,
    CeHeaderMobile,
    CeHeader,
  },
  created () {
    window.$t = this.$t
    window.$i18n = this.$i18n

    // Обработчик для перехода по ссылке из письма
    // при регистрации если приложение запущено
    // в android app режиме
    CapacitorApp.addListener('appUrlOpen', (event) => {

      const urlObj = new URL(event.url)
      const slug = event.url.split(urlObj.hostname).pop();

      // We only push to the route if there is a slug present
      if (slug) {
        const query = getQueryObjectFromString(slug)
        const cashierInfo = getCashierInfoFromQuery(query)
        this.setCashierInfo(cashierInfo)
        this.setShowCashierForm(!!cashierInfo?.cashierId)

        router.push(slug);
      }
    });

    // поведение для кнопки "Назад" в Android
    CapacitorApp.addListener('backButton', async function (event) {
      if (window.history.state.back) {
        window.history.back()
        return
      }
      await CapacitorApp.exitApp()
    })

    const { query } = this.$route
    if (query?.cashierId) {
      const cashierInfo = getCashierInfoFromQuery(query)
      this.setCashierInfo(cashierInfo)
      this.setShowCashierForm(true)
    }
  },
  data () {
    return {
      tokenSaleInfo: require('@/mock/tokenSale.json'),
      pageName: this.$route.name,
      cookieFlag: localStorage?.getItem('cookieFlag') !== 'false'
    }
  },
  computed: {
    ...mapState([
      'showMobileMenu',
      'selectedLang',
      'isProductionHostname',
      'Authorized',
      'fbToken'
    ]),
    ...mapGetters([
      'showCashExchangePopup',
      'showCashierForm',
      'showSupportPopup'
    ]),
    isAndroid () {
      return isAndroidApp
    },
    titleAlert: function () {
      return this.$store.getters.GetTitle
    },
    isServiceMan() {
      const { name } = this.$route || {}
      return SERVICE_MAN_ROUTE_NAMES.includes(name)
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    this.setupLocale()
    this.updateTokenSalePrice()
    this.roundListener()
  },
  methods: {
    fbToken,
    ...mapMutations([
      'setWindowSizeMode',
      'setCashierInfo',
      'setShowCashierForm',
      'setVisibleCashierForm',
      'setVisibleSupportPopup',
      'setSelectedLang'
    ]),
    on1inchClick () {
      this.$metrika.reachGoal('bamper_1inch_click')
      this.$gtag.event('bamper', '1inch_click')
    },
    onUniswapClick () {
      this.$metrika.reachGoal('bamper_uniswap_click')
      this.$gtag.event('bamper', 'uniswap_click')
    },
    onAccountClick () {
      this.$metrika.reachGoal('bamper_my_account_click')
      this.$gtag.event('bamper', 'my_account_click')
    },
    onTransferClick () {
      this.$metrika.reachGoal('bamper_transfer_click')
      this.$gtag.event('bamper', 'transfer_click')
    },
    onSaleClick () {
      this.$metrika.reachGoal('bamper_token_sale_click')
      this.$gtag.event('bamper', 'token_sale_click')
    },
    onExchange () {
      this.$metrika.reachGoal('bamper_exchange_click')
      this.$gtag.event('bamper', 'exchange_click')
    },
    purchaseToken () {
      this.$metrika.reachGoal('main_purchase_token')
      this.$gtag.event('main', 'purchase_token')
      router.push('/market-expansion-offering')
    },
    expressButtonClick () {
      if (window.innerWidth < 420) {
        let che = document.getElementById('RightMenuClick')
        che.checked = !che.checked
      }
    },
    updateTokenSalePrice () {
      Object.keys(this.tokenSaleInfo).forEach(key => {
        let roundStartTime = this.tokenSaleInfo[key].startTime
        let roundEndTime = this.tokenSaleInfo[key].endTime
        if (Date.now() <= roundEndTime && Date.now() > roundStartTime) {
          if (this.$store.getters.GetRound !== key) {
            this.lang.price = this.lang.price.replace('2830', this.tokenSaleInfo[key].pricePerEth)
            Store.commit('SetRound', key)
          }
        }
      })
    },
    roundListener () {
      setInterval(() => {
        this.updateTokenSalePrice()
      }, 1000)
    },
    changeCookieFlag () {
      this.cookieFlag = false
      localStorage.setItem('cookieFlag', 'false')
    },
    setupLocale () {
      if (!localStorage.getItem('locale')) {
        localStorage.setItem('locale', 'en')
        // eslint-disable-next-line no-labels
        OUTER:
        for (let preferredLang of navigator.languages) {
          for (let availableLang of ['en', 'ru', 'zh']) {
            if (availableLang === preferredLang.substr(0, 2).toLowerCase()) {
              localStorage.setItem('locale', availableLang)
              // eslint-disable-next-line no-labels
              break OUTER
            }
          }
        }
      }
      this.$i18n.locale = localStorage.getItem('locale')
      this.setSelectedLang(this.$i18n.locale)
    },
    onResize () {
      const {
        MOBILE,
        TABLET,
        DESKTOP,
        DESKTOP_1280,
        DESKTOP_1920
      } = WINDOW_MODE_NAMES
      const windowWidth = window.innerWidth
      if (windowWidth >= WINDOW_MODE_SIZES[DESKTOP_1920]) this.setWindowSizeMode(DESKTOP_1920)
      else if (windowWidth >= WINDOW_MODE_SIZES[DESKTOP_1280]) this.setWindowSizeMode(DESKTOP_1280)
      else if (windowWidth >= WINDOW_MODE_SIZES[DESKTOP]) this.setWindowSizeMode(DESKTOP)
      else if (windowWidth >= WINDOW_MODE_SIZES[TABLET]) this.setWindowSizeMode(TABLET)
      else this.setWindowSizeMode(MOBILE)
    }
  },
  watch: {
    selectedLang: {
      immediate: true,
      handler (to) {
        this.$i18n.locale = to
        this.changePrimeVueLocale(to)
        localStorage.setItem('locale', to)
      }
    }
  }
}
</script>

<style>
@import "vue-select/dist/vue-select.css";
*:not(.__custom-scroll)::-webkit-scrollbar {
  width: 0;
  height: 0;
}

*:not(.__custom-scroll)::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

*:not(.__custom-scroll)::-webkit-scrollbar-corner {
  background-color: transparent;
}

/**:not(.__custom-scroll)::-webkit-scrollbar-thumb {*/
/*  min-height: 20px;*/
/*  height: 6px;*/
/*  border: 4px solid rgba(0, 0, 0, 0);*/
/*  background-clip: padding-box;*/
/*  border-radius: 8px;*/
/*  background-color: #aaa;*/
/*}*/

.v-popper__inner div {
  color: #FFFFFF!important;
}
</style>

<style lang="stylus">
@import 'styles/global.styl'
@import 'styles/mint.styl'
#app
  touch-action manipulation
  z-index 100000

.d-none
  display none

.app-img-uniswap
  width 48px
  height 48px

.app-inch-img
  width 48px
  height 48px

.app-inch
  display flex
  text-decoration none

.app-inch-span
  display flex
  align-items center
  position relative
  color #5f5f5f
  font-size 20px
  font-weight bold
  text-decoration none

.Add
  &_Cookies
    padding 10px
    background-color Gray_Dark

    &_Block
      display flex
      justify-content: center;
      max-width: 1200px;
      margin: auto;
      padding: 0 2%;

    &_Text
      color: White;
      margin: 0 40px 0 0;
      text-align: left;

    &_Close
      cursor pointer
      border-radius: 5px;
      pading 5px
      border: 1px solid White;
      display flex
      justify-content: center;
      align-items: center
      margin: auto 0 auto auto;

      &_text
        color: White
        margin 0 4px

  &_Display_Mob
    display none

  &_ImgIBM
    cursor: pointer;
    height 60px

  &_PriceLION
    height 54px
    width 30%
    border-radius: 28px;
    display flex
    justify-content: space-around;
    align-items: center;
    padding: 0 10px;

  &_TextPriceLION
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;

  &_ButtonPriceLION
    cursor: pointer;
    border none
    height 34px
    font-family: Geometria;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: White
    background: Brend
    border-radius: 17px;
    padding 0 12%
    display flex
    align-items: center;
    text-decoration: none;
    justify-content: center;

    &:hover
      background-color Success
      color White
      transition: 0.4s
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4)

  &_ImgPriceLION
    height 40px

  &_ImgPriceLION2
    height 32px

  &_ImgPriceLION3
    height 36px

.SessionAlertEnd
  display none
  position fixed
  top 0
  right 0
  width 500px
  height 200px
  background-color Alert
  color Success
#RightMenuClick
  display none

.btn
  z-index: 1000;
  position: fixed;
  bottom 0
  left: 0
  border: none;
  width 100%
  text-align: center;
  background: rgba(245, 245, 245, 0.8);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);

  &_Boss
    padding: 16px 2%;
    max-width: 1200px;
    margin: auto;

  &_Text
    margin-top 6px;
    cursor: pointer;
    word-wrap: normal
    color: Gray_Dark;
    font-size: 10px;

    &:active
      color Brend
  &_Img
    cursor: pointer;
    width 24px
    height 24px

  &_TxtLink
    margin-top 6px;
    text-decoration: underline;
    color: Gray_Dark;
    font-size: 10px;

    &:active
      color Brend
.RightPanel
  &-MobileClick
    display flex
    justify-content: space-between;
    align-items: center;

#RightMenuClick:checked + .RightPanel
  display block
  position absolute
  right 0
  width 100%
  height: 100%
  background-color: White;
@media screen and (max-width: 1024px)
  .Add
    &_PriceLION
      width: 48%;

    &_TextPriceLION
      font-size: 14px;
      padding: 0 20px 0 10px;

    &_ImgIBM
      height 50px

    &_ButtonPriceLION
      padding: 0 4%;
      width: 170px;

    &_ImgPriceLION
      height 36px
      margin: 0 5px;

    &_ImgPriceLION2
      height 28px
      margin: 0 5px;

    &_ImgPriceLION3
      height 32px
      margin: 0 5px;

@media screen and (max-width: 786px)
  .Add
    &_a_ImgIBM
      display none

    &_PriceLION
      width auto
div.MobileFooter
  justify-content space-around
</style>

<style scoped lang="scss">
.ce-view-container {
  min-height: 100vh;
}
.ce-android-container {
  padding-left: calc(50% - 180px) !important;
  padding-right: calc(50% - 180px) !important;
}
.ce-footer-panel {
  @include forDesktop {
    //display: none;
  }
}
.ce-cookies {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
}
</style>
